import React, { useEffect, useRef, useState } from 'react';
import Select from '../../../components/Form/Select';
import Input from '../../../components/Form/Input';
import Mapdragger from '../../../components/Maps/mapDrag';
import { HiUpload } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';
// import UploadPropertyDocumentsFloorPlan from '../../AddNewProperty/UploadPropertyDocumentFloorPlan';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getData, handleRmDashboardNewReleventCrossAction, postDataForgot, postExportData } from '../../../api';
import GoogleAutoComplete from '../../../components/autoSearch/googleAutoSearch';
import { isFalsy } from '../../../utilities/commonFunction';
import { RenderMultiSelect, RenderMultiSelectProject } from '../../AddNewDemand/inputGroups';
import MultiSelect from '../../../components/select/dropDown';
import DropzoneProject from '../../../components/dragAndDrop/DropZoneProject';
import Dropzone from '../../../components/dragAndDrop/dropZone'
import CustomSelect from '../../../components/Form/Select/Customeselect';
import UploadDocument from '../../../components/UploadDocument/UploadDocument';
import TextArea from '../../../components/Form/TextArea';
import UploadPropertyDocuments from '../../../components/UploadDocument/UploadPdfProject';
import VideoDropZone from '../../../components/dragAndDrop/VideoDropZone';
// import VideoDropzone from '../../../components/dragAndDrop/VideoDropZone';
// import VideoDropZone from '../../../components/dragAndDrop/VideoDropZone';

export const CreateProject = () => {
    const navigate = useNavigate()
    const handleNavigation = (path) => {
        navigate(path);
    };
    const [inputValues, setInputValues] = useState({
        developerName: { value: '', error: '' },
        developerPOC: { value: '', error: '' },
        // checkbox: { value: false, error: '' },
        projectName: { value: '', error: '' },
        ProjectType: { value: '', error: '' },
        TransactionType: { value: [], error: '' },
        // location: { value: '', error: '' },
        City: { value: '', label: '', error: '' },
        State: { value: '', label: '', error: '' },
        Pincode: { value: '', error: '' },
        ProjectStatus: { value: '', error: '' },
        PosessionDate: { value: '', error: '' },
        area: { value: '', error: '' },
        AgeofProject: { value: '', error: '' },
        PropertyType: { value: [], error: '' },
        // 
        UnitsAvailable: { value: '', error: '' },
        unitSizeMax: { value: '', error: '' },
        unitSizeMin: { value: '', error: '' },
        NoOFBuildings: { value: '', error: '' },
        ReraID: { value: '', error: ' ' },
        CarpetArea: { value: '', error: '' },
        Properties: { value: [], error: '' },
        // 
        AboutProject: { value: '', error: '' },
        mapLocation: { value: '', error: '' },
        FeatureAmenities: { value: [], error: '' },
        // 
        file: { value: '', error: '' },
        minPrice: { value: '', error: '' },
        maxPrice: { value: '', error: '' },
        OCStatus: { value: '', error: '' },
        Brochure: { value: '', error: '' },
        // BusinesSpace: { value: '', error: '' },
        CurrentTenants: { value: '', error: '' },
        AbouttheDeveloper: { value: '', error: '' },
        NoofParkingsAvailable: { value: '', error: '' },
        AssigningPT: { value: [], error: '' },
        // 
        AssigingRM: { value: '', error: '' },
        ChooseUserBase: { value: [], error: '' },
        // 
        enduse: { value: '', error: '' },
        microLocation: { value: '', error: '' },
        // CAMCharges: { value: '', error: '' }
    });


    console.log(inputValues, "inputValues")


    const [microLocationError, setMicroLocationError] = useState(false);
    const [interioruploadedImages, setinterioruploadedImages] = useState([]);
    const [exterioruploadedImages, setexterioruploadedImages] = useState([]);
    const [exterioruploadedVideos, setexterioruploadedVideos] = useState([]);
    const [interiorImagesError, setInteriorImagesError] = useState('');
    const [exteriorImagesError, setExteriorImagesError] = useState('');
    console.log(exteriorImagesError, interiorImagesError);
    const [googleMapError, setGoogleMapError] = useState(false);

    console.log({ exterioruploadedVideos });
    // console.log(interioruploadedImages, "interioruploadedImages");


    const [gotoStep, setGotoStep] = useState(0);
    const [propertyDetail, setPropertyDetail] = useState({});
    const [step, setStep] = useState(0);
    const [initData, setInitData] = useState({});
    const [documentproperytyInput, setDocumentPropertyInput] = useState([]);
    console.log(documentproperytyInput, "documentproperytyInput");
    const [uploadDocumentStepButtonRef, setUploadDocumentStepButtonRef] = useState(null);
    const [UploadFloorChanged, setUploadFloorChanged] = useState(false);
    const [type, setType] = useState('add');

    // Define a state for isExitData
    const [isExitData, setIsExitData] = useState(false);
    const [DeveloperID, setDeveloperID] = useState([])
    const [POCID, setPOCID] = useState([])
    const [projectTypeId, setProjectTypeID] = useState([])
    const [PropertyID, setPropertyID] = useState([])
    const [PropertyNameID, setPropertyNameID] = useState()
    const [EndUses, setEndUses] = useState([])
    const [Pteam, setPteam] = useState([])
    const [AMenity, setAMenity] = useState([])
    const [properytyObject, setProperyObject] = useState()
    const [city, setCity] = useState([])
    const [cityData, setCityData] = useState(true)
    const [projectDrafID, setProjectDrafId] = useState()

    const [State, setState] = useState([])
    // Function to handle step change

    const handleStepChange = (newStep) => {
        setStep(newStep);

    };


    // api integration 

    // get developer
    const GetDeveloper = async () => {
        try {
            const url = "/project/getAllDeveloperWithoutOperator/?pageNo=1&limit=100&type=Developer";
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const mynewData = data?.companies?.filter((company) => company?.isActive)?.
                    map((value) => {

                        return {
                            value: value?._id,
                            name: value?.companyName,
                            state: value.state.map((v) => {
                                return {
                                    value: v?._id,
                                    label: v?.name
                                }
                            })

                        }
                    })
                setDeveloperID(mynewData)


            }
        } catch (e) {
            console.log(e, "error")
        }
    };

    // get draft api
    // get Poc
    const GetDraftApi = async () => {
        try {
            const url = `/project/createDraftProject`;
            const getList = await postDataForgot(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                setProjectDrafId(data?.projectId)

                localStorage.setItem('ProjectId', data?.projectId);

            }
        } catch (e) {
            console.log(e, "error")
        }
    };



    // get Poc
    const GetPoc = async (id) => {
        try {
            const url = `/brand/getAllPoc/${id}`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setPOCID(newData)

            }
        } catch (e) {
            console.log(e, "error")
        }
    };

    // getState

    const GetState = async (id) => {
        try {
            const url = `/brand/${id}`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.state?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })
                const PropertyID = data?.propertyTypes?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setPropertyID(PropertyID)


                setState(newData)

            }
        } catch (e) {
            console.log(e, "error")
        }
    };

    // project Type

    const GetProjectTypeID = async () => {
        try {
            const url = `/project/getProjectTypes`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.getRes?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setProjectTypeID(newData)

            }
        } catch (e) {
            console.log(e, "error")
        }
    };

    //  

    // project Type

    const GetamenityData = async () => {
        try {
            const url = `/amenity`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setAMenity(newData)


            }
        } catch (e) {
            console.log(e, "error")
        }
    };
    // property ID

    // const GetPropertyID = async () => {
    //     try {
    //         const url = `/propertyType`;
    //         const getList = await getData(url, {}, {});
    //         const { statusCode, data } = getList;
    //         if (statusCode === 200) {


    //             const newData = data?.map((value) => {
    //                 return value.filter((v)=>{
    //                     return v?.name !== "Flex Space" 
    //                 })
    //                 // if (value?.name !== "Flex Space") {
    //                 //     return {
    //                 //         value: value?._id,
    //                 //         label: value?.name

    //                 //     }
    //                 // }

    //             })

    //             console.log(newData, "data");

    //             setPropertyID(newData)

    //         }
    //     } catch (e) {
    //         console.log(e, "error")
    //     }
    // };

    // getPropertiesBased

    const getPropertiesBased = async (devID, city) => {

        try {

            const url = `/project/getPropertiesBasedOnDeveloper?developerId=${devID}&location=${inputValues?.City?.value?.label}`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.getRes?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.productId?.display

                    }
                })

                setPropertyNameID(newData)

            }
        } catch (e) {
            console.log(e, "error")
        }
    };


    // /enduse/getEndUses
    const getEndUses = async () => {
        try {
            const url = `/enduse/getEndUses`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setEndUses(newData)

            }
        } catch (e) {
            console.log(e, "error")
        }
    };


    // pt

    const getPt = async () => {
        try {
            const url = `/project/getAllPhysicalTeamPoc`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.map((value) => {
                    console.log({ value });
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })


                setPteam(newData)


            }
        } catch (e) {
            console.log(e, "error")
        }
    };


    // create Project

    const CreateprojectAPi = async (body) => {
        try {
            const url = `/project/createProject`;
            const getList = await handleRmDashboardNewReleventCrossAction(url, body);
            const { statusCode, data } = getList;
            if (statusCode === 200) {
                handleNavigation('/project')
            }
        } catch (e) {
            console.log(e, "error")
        }
    };



    const Createcity = async (body) => {
        try {
            const url = `/country/getCitiesByState`;
            const getList = await postDataForgot(url, {}, body);
            const { statusCode, data } = getList;
            if (statusCode === 200) {

                const newData = data?.slice(1)?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name

                    }
                })

                setCityData(false)
                setCity(newData)
            }
        } catch (e) {
            console.log(e, "error")
        }
    };



    // city
    const state = DeveloperID.flatMap((v) => {
        return v.state
    })
    // clg


    useEffect(() => {
        GetDeveloper()
        GetProjectTypeID()
        // GetPropertyID()
        getEndUses()
        GetamenityData()
        getPt()


    }, [])


    useEffect(() => {
        if (inputValues?.developerName?.value && inputValues.City.label) {

            const handler = setTimeout(() => {
                getPropertiesBased(inputValues?.developerName?.value, inputValues.City.label)
            }, 2000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [inputValues.City.label, inputValues?.developerName?.value, 2000])


    useEffect(() => {
        if (inputValues?.State?.value?.value) {
            Createcity({
                "id": [
                    inputValues?.State?.value?.value
                ]

            })
        }
    }, [inputValues?.State?.value?.value])


    useEffect(() => {
        if (inputValues?.developerName?.value) {
            GetPoc(inputValues?.developerName?.value)
        }
    }, [inputValues?.developerName?.value])

    useEffect(() => {
        if (inputValues?.developerName?.value) {
            GetState(inputValues?.developerName?.value)
        }
    }, [inputValues?.developerName?.value])


    useEffect(() => {

        GetDraftApi()

    }, [])


    const coworkGoogleMapDivRef = useRef(null);
    const handleChangeMap = (newLocation) => {
        handleInputChange('mapLocation', newLocation);
    };



    const pocList = [
        { value: '1', label: 'aman' },
        { value: '2', label: 'rutwik' },
        { value: '3', label: 'pratik' },
    ];
    const OCStatusList = [
        { value: '1', label: 'Yes' },
        { value: '2', label: 'No' },

    ];

    const projectStatus = [
        {
            value: 'Ready to Move', label: 'Ready to Move'
        },
        {
            value: 'Under Construction', label: 'Under Construction'
        }
    ]

    const UserType = [
        {
            value: 'Brand', label: 'Brand'
        },
        // {
        //     value: 'Developer', label: 'Developer'
        // },
        {
            value: "Agent", label: 'Agent'
        }
    ]


    const trysitionType = [
        { value: 'Lease', label: 'Lease' },
        { value: 'Sale', label: 'Sale' },
        { value: 'Management contract', label: 'Management contract' },
        { value: 'Revenue share', label: 'Revenue share' },
        { value: 'MG Revenue share', label: 'MG Revenue share' },

    ];

   
    // const PorjectAge = [
    //     {
    //         value: 3, label: 'Less than 5 years',
    //     },
    //     {
    //         value: 2, label: '6-10 years'
    //     }
    // ]
    const PorjectAge = [
        {
            value: 3,
            label: 'Less than 5 years',
        },
        {
            value: 2,
            label: '5 - 10 years',
        },
        {
            value: 1,
            label: 'Above 10 years',
        }
    ];
    
    const PropertyType = [
        { label: 'Retail', value: '1' },
        { label: 'Office Space', value: '2' },
        { label: 'flex space', value: '2' },
    ];


    const handleInputChange = (fieldName, value, label) => {

        console.log({ fieldName, value, label });

        if (fieldName === "City") {
            setInputValues(prevValues => ({
                ...prevValues,
                microLocation: {
                    value: "",
                    label: "",
                    error: false,
                },
            }));
        }

        if (fieldName === "ProjectStatus") {
            setInputValues(prevValues => ({
                ...prevValues,
                PosessionDate: {
                    value: "",
                    label: "",
                    error: false,
                },
                AgeofProject: {
                    value: {

                    },
                    error: '',
                },

            }));
        }

        if (fieldName === "State") {
            setErrorMsgfordeveloperName(false);
            setInputValues(prevValues => ({
                ...prevValues,
                PropertyType: {
                    value: [],
                    error: false,
                },
                State: {
                    value: "",
                    label: "",
                    error: false,
                },

                City: {
                    value: "",
                    label: "",
                    error: false,
                },
                Pincode: {
                    value: "",
                    error: false,
                },
                microLocation: {
                    value: [],
                    error: false,
                }

            }));
        }

        if (fieldName === "developerName") {
            setInputValues(prevValues => ({
                ...prevValues,
                PropertyType: {
                    value: [],
                    error: false,
                },
                State: {
                    value: "",
                    label: "",
                    error: false,
                },

                City: {
                    value: "",
                    label: "",
                    error: false,
                },
                Pincode: {
                    value: "",
                    error: false,
                },
                developerPOC: {
                    value: "",
                    label: "",
                    error: false,
                },
                Properties: {
                    value: [],
                    error: false,
                },
                microLocation: {
                    value: [],
                    error: false,
                }
            }));
        }




        if (fieldName === 'Pincode' && value.length > 6) {
            return;
        }

        setInputValues(prevValues => ({
            ...prevValues,
            [fieldName]: {
                ...prevValues[fieldName],
                value,
                label,
                error: validateField(fieldName, value),
            },
        }));

    };


    useEffect(() => {
        if (exterioruploadedImages?.data?.length >= 3) {
            setExteriorImagesError('')
        }
    }, [exterioruploadedImages?.data])

    useEffect(() => {
        if (interioruploadedImages?.data?.length >= 3) {
            setInteriorImagesError('')
        }
    }, [interioruploadedImages?.data])






    const validateField = (fieldName, value) => {

        switch (fieldName) {
            case 'developerName':
                return value ? "" : 'Please select developer name';
            case 'developerPOC':
                return value ? "" : 'Please select developer POC';
            case 'projectName':
                return value ? "" : 'Please enter project name ';
            case 'TransactionType':
                return value === 0 ? 'Please select at least one  transaction type ' : "";
            case 'ProjectType':
                return value ? "" : 'Please select project type ';
            case 'City':
                return value ? "" : 'Please select city  ';
            case 'ProjectStatus':
                return value ? "" : 'Please select  project status'
            case 'AgeofProject':
                if (inputValues?.ProjectStatus?.value === "Ready to Move") {
                    return value ? '' : 'Please select age of project';
                }
                return '';
            // case 'enduse':
            case 'OCStatus':
                // case 'AssigningPT':
                return value ? "" : 'Please select OC status '
            case 'location':
            // case 'projectName':
            case 'State':
                return value ? "" : 'Please select state  ';
            case "PosessionDate":
                if (inputValues?.ProjectStatus?.value == "Under Construction") {
                    return value ? '' : 'Please select posession date ';
                }
                return '';
            // case "area":
            //     return 'Please enter build-up area  ';
            // case "NoOFBuildings":

            // case "ReraID":
            // case 'CarpetArea':
            //     return 'Please enter carpet area  ';
            case 'AboutProject':
                return value ? "" : 'Please enter about project   ';
            case 'microLocation':
                const textField = /.+/;
                return textField.test(value) ? '' : 'Please enter location';
            case 'mapLocation':
                const textFieldRegex = /.+/;
                return textFieldRegex.test(value) ? '' : 'Please enter location name';
            // case 'minPrice':
            //     return value <= 0 ? 'Min price must be greater than 0' : '';
            // case 'maxPrice':
            //     return value <= 0 ? 'Max price must be greater than 0' : '';
            case 'Pincode':
                if (value.trim() === '') {
                    return value ? "" : 'Please enter a valid 6-digit pincode';
                }
                const PinCodeRegex = /^\d{6}$/;
                return PinCodeRegex.test(value) ? '' : 'Please enter a valid 6-digit pincode';
            case 'FeatureAmenities':
                return value === 0 ? 'Please select at least one amenity' : '';
            case 'PropertyType':
                return value === 0 ? 'Please select at least one PropertyType' : '';
            // case 'AssigningPT':
            //     return value === 0 ? 'Please select at least one Physical Team POC' : '';
            case 'ChooseUserBase':
                return value === 0 ? 'Please select at least one ChooseUserBase' : '';
            default:
                return '';
        }
    };



    const handleSubmit = (e) => {

        e.preventDefault();
        let hasError = false;
        const updatedInputValues = {};
        // console.log({ inputValues1231232: inputValues })

        for (const fieldName in inputValues) {
            const value = inputValues[fieldName].value;
            const error = validateField(fieldName, value);
            updatedInputValues[fieldName] = {
                ...inputValues[fieldName],
                error,
            };

            if (error) {
                hasError = true;
            }
        }

        // Check for errors in array fields
        if (inputValues.ChooseUserBase.value.length === 0) {
            updatedInputValues.ChooseUserBase = {
                ...inputValues.ChooseUserBase,
                error: 'Please select at least one choose user base',
            };
            hasError = true;
        }

        if (inputValues.TransactionType.value.length === 0) {
            updatedInputValues.TransactionType = {
                ...inputValues.TransactionType,
                error: 'Please select at least one  Trysition Type',
            };
            hasError = true;
        }

        // if (inputValues.AssigningPT.value.length === 0) {
        //     updatedInputValues.AssigningPT = {
        //         ...inputValues.AssigningPT,
        //         error: 'Please select at least one  Physical Team POC',
        //     };
        //     hasError = true;
        // }

        if (inputValues.FeatureAmenities.value.length === 0) {
            updatedInputValues.FeatureAmenities = {
                ...inputValues.FeatureAmenities,
                error: 'Please select at least one feature amenities',
            };
            hasError = true;
        }

        if (inputValues.PropertyType.value.length === 0) {
            updatedInputValues.PropertyType = {
                ...inputValues.PropertyType,
                error: 'Please select at least one property type',
            };
            hasError = true;
        }
        const minPrice = parseFloat(updatedInputValues.minPrice.value);
        const maxPrice = parseFloat(updatedInputValues.maxPrice.value);

        // console.log('Min Price:', minPrice);
        // console.log('Max Price:', maxPrice);

        if (minPrice >= maxPrice) {
            updatedInputValues.minPrice.error = 'Min price must be less than Max price';
            updatedInputValues.maxPrice.error = 'Max price must be greater than Min price';
            hasError = true;
        }

        const unitSizeMin = parseFloat(updatedInputValues.unitSizeMin.value);
        const unitSizeMax = parseFloat(updatedInputValues.unitSizeMax.value);

        // console.log('Min Price:', minPrice);
        // console.log('Max Price:', maxPrice);

        if (unitSizeMin >= unitSizeMax) {
            updatedInputValues.unitSizeMin.error = 'Min unit  must be less than Max unit';
            updatedInputValues.unitSizeMax.error = 'Max unit must be greater than Min unit';
            hasError = true;
        }


        const area = parseFloat(updatedInputValues.area.value);
        const carpetArea = parseFloat(updatedInputValues.CarpetArea.value);

        // console.log('Area:', area);
        // console.log('Carpet Area:', carpetArea);

        if (isNaN(area) || isNaN(carpetArea)) {
            updatedInputValues.area.error = 'Please enter Built-up area';
            updatedInputValues.CarpetArea.error = 'Please enter Carpet area';
            hasError = true;
        } else if (area <= carpetArea) {
            updatedInputValues.area.error = 'Built-up must be greater than Carpet area';
            updatedInputValues.CarpetArea.error = 'Carpet area must be less than Built-up area';
            hasError = true;
        }


        const interiorImageCount = interioruploadedImages.data.length;
        const exteriorImageCount = exterioruploadedImages.data.length;

        if (interiorImageCount < 3) {
            setInteriorImagesError('Please upload at least 3 interior images');
            hasError = true;
        } else {
            setInteriorImagesError('');
        }

        if (exteriorImageCount < 3) {
            setExteriorImagesError('Please upload at least 3 exterior images');
            hasError = true;
        } else {
            setExteriorImagesError('');
        }

        setInputValues(updatedInputValues);


        if (!hasError) {
            // Form submission logic
            console.log("doneee")
            CreateprojectAPi({
                ...(inputValues?.CAMCharges?.value) && { "camCharges": inputValues?.CAMCharges?.value },
                "projectId": projectDrafID,
                "developerId": inputValues?.developerName?.value,
                "developerPocId": inputValues?.developerPOC?.value?.value,
                "projectName": inputValues?.projectName?.value,
                "projectTypeId": inputValues?.ProjectType?.value,
                // "transactionType": inputValues?.TransactionType?.value,
                ...(inputValues?.TransactionType?.value) && {
                    "transactionType": inputValues?.TransactionType?.value?.map((v) => {
                        return v.value

                    })
                },
                "location": inputValues?.City?.value?.label,
                "microLocation": inputValues?.microLocation,
                "stateId": {
                    "_id": inputValues?.State?.value?.value,
                    "name": inputValues?.State?.value?.label
                },
                "pincode": inputValues?.Pincode?.value,
                "projectStatus": inputValues?.ProjectStatus?.value,
                ...(inputValues?.AgeofProject?.value?.value) && { "ageOfProject": inputValues?.AgeofProject?.value?.value },
                ...(inputValues?.PropertyType?.value) && {
                    "propertyTypes": inputValues?.PropertyType?.value?.map((v) => {
                        return {
                            "name": v.label,
                            "_id": v.value
                        }
                    })
                },
                "builtupArea": inputValues?.area?.value,
                ...(inputValues?.UnitsAvailable?.value) && { "unitAvailable": inputValues?.UnitsAvailable?.value },
                ...(inputValues?.unitSizeMax?.value) && { "unitSizeMax": inputValues?.unitSizeMax?.value },
                ...(inputValues?.unitSizeMin?.value) && { "unitSizeMin": inputValues?.unitSizeMin?.value },
                "carpetArea": inputValues?.CarpetArea?.value,
                ...(inputValues?.NoOFBuildings?.value) && { "noOfBuildingOrTower": inputValues?.NoOFBuildings?.value },
                ...(inputValues?.ReraID?.value) && { "reraId": inputValues?.ReraID?.value },
                "properties": inputValues?.Properties?.value?.map((v) => {
                    return v.value
                }),
                ...(inputValues?.AboutProject?.value) && { "aboutProject": inputValues?.AboutProject?.value },
                ...(inputValues?.mapLocation?.value) && { "gMapLatLong": inputValues?.mapLocation?.value },
                ...(inputValues?.FeatureAmenities?.value) && {
                    "otherAmenities": inputValues?.FeatureAmenities?.value?.map((v) => {
                        return v?.value

                    })
                },
                ...(inputValues?.OCStatus?.value) && { "ocStatus": inputValues?.OCStatus?.value == '1' ? true : false },
                "otherDocuments": {
                    "brochure": documentproperytyInput[1]?.name == "brochure" && documentproperytyInput[1]?.uploadFiles || [],
                    "floorPlans": documentproperytyInput[0]?.name == "floorPlans" && documentproperytyInput[0]?.uploadFiles || []
                },
                ...(inputValues?.enduse?.value) && {
                    "endUse": inputValues?.enduse?.value?.map((v) => {
                        return v.value
                    })
                },
                "imagesAndVideos": {
                    "exterior": exterioruploadedImages?.data,
                    "interior": interioruploadedImages?.data,
                    "videos": exterioruploadedVideos?.data
                },
                ...(inputValues?.CurrentTenants?.value) && { "tenants": inputValues?.CurrentTenants?.value },
                ...(inputValues?.NoofParkingsAvailable?.value) && { "parking": inputValues?.NoofParkingsAvailable?.value },
                ...(inputValues?.AssigningPT?.value) && {
                    "ptIds": inputValues?.AssigningPT?.value?.map((v) => {
                        return v.value

                    })
                },
                ...(inputValues?.ChooseUserBase?.value) && {
                    "userType": inputValues?.ChooseUserBase?.value?.map((v) => {
                        return v.value

                    })
                },
                ...(inputValues?.PosessionDate?.value) && { "possesionDate": inputValues?.PosessionDate?.value },
                ...(inputValues.minPrice?.value) && { "priceRangeMin": inputValues.minPrice?.value },
                ...(inputValues.maxPrice?.value) && { "priceRangeMax": inputValues.maxPrice?.value },

            }
            )
            console.log('Form submitted successfully!');
        } else {
            // setInputValues(updatedInputValues);
            console.log("notdonee")
        }
    };



    // Function to handle uploaded images
    const handleImagesAllinterior = (images) => {
        setinterioruploadedImages(images);
    };

    const handleImagesAllexterior = (images) => {
        setexterioruploadedImages(images);
    };
    const handleImagesAllVideos = (images) => {
        setexterioruploadedVideos(images);
    };


    const handelCheckLocationError = (name, value) => {
        if (
            name === 'microLocation' &&
            isFalsy(value?.placeId) &&
            isFalsy(value?._id)
        ) {
            setMicroLocationError(true);
            setProperyObject((pre) => ({
                ...pre,
                microLocation: {},
                locationmap: '',
            }));
        }
    };


    const handleSelectLocation = (suggestion, action) => {
        // setMicroLocationError(false);
        let Locationdata = {
            ...suggestion,
            _id: suggestion?.placeId ? suggestion?.placeId : suggestion?._id,
            name: suggestion?.description,
        };
        setInputValues((prev) => ({
            ...prev,
            microLocation: Locationdata,
            // locationmap: JSON.stringify(Locationdata),
        }));
    };

    const flattenNestedValues = (data) => {
        const flattenedValues = [];
        const traverse = (item) => {
            if (Array.isArray(item.value)) {
                item.value.forEach((nestedItem) => traverse(nestedItem));
            } else {
                flattenedValues.push({ value: item.value, label: item.label });
            }
        };
        data.forEach((item) => traverse(item));
        return flattenedValues;
    };

    const handleMultiselect = (fieldName, data) => {
        const flattenedData = flattenNestedValues(data);
        setInputValues((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                value: flattenedData,
                error: validateField(fieldName, data),
            },
        }));
    };

    // MultiSelect handlers
    const handleMultiselectPropertyType = data => handleMultiselect('PropertyType', data);
    const handleMultiselectProperties = data => handleMultiselect('Properties', data);
    const handleMultiselectFeatureAmenities = data => handleMultiselect('FeatureAmenities', data);
    const handleMultiselectAssigningPT = data => handleMultiselect('AssigningPT', data);
    const handleMultiselectChooseUserBase = data => handleMultiselect('ChooseUserBase', data);
    const handleMultiselectEndUse = data => handleMultiselect('enduse', data);
    const handleMultiselectTransactionType = data => handleMultiselect('TransactionType', data);




    const [ErrorMsgfordeveloperName, setErrorMsgfordeveloperName] = useState(false)

    useEffect(() => {
        if (inputValues.developerName.value) {
            setErrorMsgfordeveloperName(false);
        }
    }, [inputValues.developerName.value]);


    // const handleDeveloperNameChange = (value) => {
    //     setInputValues((prev) => ({
    //         ...prev,
    //         developerName: { value, error: '' },
    //         developerPOC: { value: '', error: '' },
    //     }));
    //     setErrorMsgfordeveloperName(false);
    // };

    const handleCheckDeveloperCheckBox = () => {
        setErrorMsgfordeveloperName(false);
    };


    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');

        setMinDate(`${yyyy}-${mm}-${dd}`);
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit} className='pt-20 mt-48 border bg-slate-500'>
                <div className='rounded-2 p-3 ' style={{ background: 'white' }}>
                    <div className='d-flex gap-2 align-items-center self-items-center'>
                        <FaChevronLeft onClick={() => { handleNavigation('/project') }} style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }} />
                        <h5 className='pt-2'>Post  Project</h5>
                    </div>
                    {/* primary Details */}
                    <div className='p-2 rounded-2 my-3' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}>    Primary Details</div>
                    <div className='row row-cols-2  '>
                        {/*  */}
                        <div className=''>
                            <Select
                                label="Developer Name*"
                                options={DeveloperID}
                                onChange={(e) => handleInputChange('developerName', e.target.value)}
                                errorMessage={inputValues.developerName.error}
                                isInvalid={!!inputValues.developerName.error}

                                onClick={handleCheckDeveloperCheckBox}
                            />
                            {ErrorMsgfordeveloperName && <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>Please select a Developer Name first </p>}

                        </div>

                        {/*  */}
                        <div className='pb-3 ' style={{ zIndex: "4000" }}>
                            {/* <Select
                                label="Developer POC*"
                                options={POCID}
                                onChange={(e) => handleInputChange('developerPOC', e.target.value)}
                                errorMessage={inputValues.developerPOC.error}
                                isInvalid={!!inputValues.developerPOC.error}
                                onClick={() => {
                                    if (!inputValues.developerName.value) {
                                        setErrorMsgfordeveloperName(true);
                                    }
                                }}

                            // disabled={inputValues.developerName.value ? false : true}
                            /> */}
                            <RenderMultiSelectProject
                                checboxDisabled={true}
                                label="Developer POC*"
                                name="State"
                                value={inputValues.developerPOC.value}
                                onChange={(value, label) => handleInputChange('developerPOC', value)
                                }
                                options={POCID}
                                errorMessage={inputValues.developerPOC.error}
                                isInvalid={!!inputValues.developerPOC.error}
                                disabled={inputValues.developerName?.value ? false : true}
                                placeholder={"Select State"}
                                // disabled={
                                //     !cityData ? false : true
                                // }
                                isMulti={false}
                            />


                        </div>
                        {/*  */}



                        {/*  */}

                        <div className=''>
                            <Input
                                label={"Project  Name*"}
                                type={"text"}
                                placeholder={"Enter Project name"}
                                name="projectName"
                                value={inputValues?.projectName?.value}
                                onChange={(e) => handleInputChange('projectName', e.target.value)}
                                disabled={false}
                            // errorTrue={false}
                            // errorMessage={inputValues.projectName.error}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.projectName.error} </p>
                        </div>

                        <div className='pb-3'>
                            <Select
                                label="Project Type*"
                                options={projectTypeId}
                                onChange={(e) => handleInputChange('ProjectType', e.target.value)}
                                errorMessage={inputValues.ProjectType.error}
                                isInvalid={!!inputValues.ProjectType.error}
                            />


                        </div>
                        {/*  */}
                        <div className='pb-3 ' style={{ zIndex: "5000" }}>
                            {/* <Select
                                label="Transaction Type*"
                                options={trysitionType}
                                onChange={(e) => handleInputChange('TransactionType', e.target.value)}
                                errorMessage={inputValues.TransactionType.error}
                                isInvalid={!!inputValues.TransactionType.error}
                            /> */}
                            <MultiSelect
                                // disabled={disabled}

                                errormessage={inputValues.TransactionType.error}
                                onChange={handleMultiselectTransactionType}
                                isMulti={true}
                                options={trysitionType}
                                value={inputValues.TransactionType?.value}
                                lable={'Transaction Type*'}
                            // size="w-100"
                            />
                        </div>






                        {/*  */}

                        <div className=''>


                            <RenderMultiSelectProject
                                checboxDisabled={true}
                                label="State*"
                                name="State"
                                value={inputValues.State.value}
                                onChange={(value, label) => handleInputChange('State', value, label)
                                }
                                options={State}
                                errorMessage={inputValues.State.error}
                                isInvalid={!!inputValues.State.error}
                                placeholder={"Select State"}
                                // disabled={
                                //     !cityData ? false : true
                                // }
                                isMulti={false}
                            />
                        </div>

                        <div className='pb-2 ' style={{ zIndex: "4000" }} >


                            <RenderMultiSelectProject
                                checboxDisabled={true}
                                label="City*"
                                name="City"
                                value={inputValues.City.value}
                                onChange={(value, label) => handleInputChange('City', value, label)
                                }

                                options={city}
                                isInvalid={!!inputValues.City.error}
                                errorMessage={inputValues.City.error}
                                placeholder={"Select city"}
                                disabled={
                                    inputValues?.State?.value?.value ? false : true
                                }
                                isMulti={false}
                            />

                            {/* <p style={{ fontSize: '16px', color: '#406065' }}>Please select a state first </p> */}
                        </div>

                        <div className=''>
                            <Input
                                label={"Pincode*"}
                                type={"number"}
                                placeholder={"Enter Pincode"}
                                name="Pincode"
                                value={inputValues?.Pincode?.value}
                                onChange={(e) => handleInputChange('Pincode', e.target.value)}
                                disabled={false}
                            // errorTrue={true}
                            // errorMessage={inputValues.Pincode.error}

                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.Pincode.error} </p>
                        </div>
                        <div className='' style={{ zIndex: "3000" }}>
                            <GoogleAutoComplete
                                cityValue={''}
                                label="Location * "
                                value={inputValues["microLocation"]}
                                handleSelectLocation={handleSelectLocation}
                                errorMessage={inputValues?.microLocation?.error}
                                size={'m-0'}

                                disabled={!inputValues.City.value ? true : false}
                            // handleBlur={() =>
                            //   handelCheckLocationError(
                            //     item.name,
                            //     properytyObject[item.name]
                            //   )
                            // }
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-5px' }}>{inputValues.microLocation.error} </p>
                        </div>
                        {/*  */}

                        <div className='pb-2'>
                            <Select
                                label="Project Status*"
                                options={projectStatus}
                                onChange={(e) => handleInputChange('ProjectStatus', e.target.value)}
                                // errorMessage={inputValues.ProjectStatus.error}
                                isInvalid={!!inputValues.ProjectStatus.error}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.ProjectStatus.error} </p>

                        </div>


                        {/*  */}

                        <div className=''>
                            <Input
                                label={"Posession Date* "}
                                type={"date"}
                                placeholder={"Enter Posession Date"}
                                name="PosessionDate"
                                min={minDate}
                                value={inputValues?.PosessionDate?.value}
                                onChange={(e) => handleInputChange('PosessionDate', e.target.value)}
                                disabled={inputValues?.ProjectStatus?.value == "Ready to Move" ? true : false}
                                errorTrue={true}
                            // errorMessage={inputValues.PosessionDate.error}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.PosessionDate.error} </p>
                        </div>




                        {/*  */}
                        <div className='pb-2'>
                            {/* <Select
                                label="Age of Project*"
                                options={PorjectAge}
                                onChange={(e) => handleInputChange('AgeofProject', e.target.value)}
                                errorMessage={inputValues.AgeofProject.error}
                                isInvalid={!!inputValues.AgeofProject.error}
                                disabled={inputValues?.ProjectStatus?.value == "Ready to Move" ? false : true}
                            /> */}
                            <RenderMultiSelectProject
                                checboxDisabled={true}
                                label="Age of Project*"
                                name="AgeofProject"
                                value={inputValues.AgeofProject.value}
                                onChange={(value, label) => handleInputChange('AgeofProject', value)
                                }
                                options={PorjectAge}
                                errorMessage={inputValues.AgeofProject.error}
                                isInvalid={!!inputValues.developerPOC.error}
                                disabled={inputValues?.ProjectStatus?.value == "Ready to Move" ? false : true}
                                placeholder={"Age of Project*"}
                                // disabled={
                                //     !cityData ? false : true
                                // }
                                isMulti={false}
                            />
                        </div>

                        {/*  */}
                        <div className='pb-2' style={{ zIndex: "2000" }}>

                            <MultiSelect
                                // disabled={disabled}
                                errormessage={inputValues.PropertyType.error}
                                onChange={handleMultiselectPropertyType}
                                isMulti={true}
                                options={PropertyID}
                                value={inputValues.PropertyType?.value}
                                lable={'Property Type*'}
                                size="w-100"
                            />
                        </div>



                        {/*  */}
                        <div className=''>
                            <div>
                                <Input
                                    label={"Total Built-up Area in sq.ft* "}
                                    type={"number"}
                                    placeholder={"Enter Total Built-up Area in sq.ft "}
                                    name="area"
                                    sufix={"sq.ft"}
                                    value={inputValues?.area?.value}
                                    onChange={(e) => handleInputChange('area', e.target.value)}
                                    disabled={false}
                                    errorTrue={true}
                                // errorMessage={inputValues.area.error}
                                />
                                <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.area.error} </p>
                            </div>

                        </div>
                        <div className=''>
                            <Input
                                label={"Total Carpet Area in sq.ft* "}
                                type={"number"}
                                placeholder={"Enter Total Carpet Area in sq.ft     "}
                                name="CarpetArea"
                                sufix={"sq.ft"}
                                value={inputValues?.CarpetArea?.value}
                                onChange={(e) => handleInputChange('CarpetArea', e.target.value)}
                                disabled={false}
                                errorTrue={true}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.CarpetArea.error} </p>
                        </div>


                        {/*  */}
                        <div className=''>
                            <Input
                                label={"Units Available"}
                                type={"number"}
                                placeholder={"Enter Units Available"}
                                name="UnitsAvailable"
                                value={inputValues?.UnitsAvailable?.value}
                                onChange={(e) => handleInputChange('UnitsAvailable', e.target.value)}
                                disabled={false}
                                // errorTrue={true}
                                sufix={"Units"}
                            />
                        </div>

                        <div className=''>
                            <Input
                                label={"Unit Size Min "}
                                type={"number"}
                                placeholder={"Enter Unit Sizes Min"}
                                name="unitSizeMin"
                                value={inputValues?.unitSizeMin?.value}
                                onChange={(e) => handleInputChange('unitSizeMin', e.target.value)}
                                disabled={false}
                                sufix={"sq.ft"}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.unitSizeMin.error} </p>
                        </div>
                        <div className=''>
                            <Input
                                label={"Unit Size Max "}
                                type={"number"}
                                placeholder={"Enter Unit Sizes Max "}
                                name="unitSizeMax"
                                value={inputValues?.unitSizeMax?.value}
                                onChange={(e) => handleInputChange('unitSizeMax', e.target.value)}
                                disabled={false}
                                sufix={"sq.ft"}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.unitSizeMax.error} </p>
                        </div>


                        {/*  */}

                        <div className=''>
                            <Input
                                label={"Number of Buildings/Towers "}
                                type={"number"}
                                placeholder={"Enter Number of Buildings/Towers"}
                                name="NoOFBuildings"
                                value={inputValues?.NoOFBuildings?.value}
                                onChange={(e) => handleInputChange('NoOFBuildings', e.target.value)}
                                disabled={false}
                                errorTrue={true}
                            // errorMessage={inputValues.NoOFBuildings.error}
                            />
                        </div>

                        <div className=''>
                            <Input
                                label={"Rera Id "}
                                type={"text"}
                                placeholder={"Enter Rera Id"}
                                name="ReraID"
                                value={inputValues?.ReraID?.value}
                                onChange={(e) => handleInputChange('ReraID', e.target.value)}
                                disabled={false}
                                errorTrue={true}
                            />
                        </div>

                        <div className=''>
                            <Input
                                label={"CAM Charges"}
                                type={"number"}
                                sufix={"₹"}
                                placeholder={"Enter CAM Charges"}
                                name="CAMCharges"
                                value={inputValues?.CAMCharges?.value}
                                onChange={(e) => handleInputChange('CAMCharges', e.target.value)}
                                disabled={false}
                                errorTrue={true}
                            />
                        </div>




                    </div>
                    {/*  */}

                    <div className='p-2  rounded-2 mb-3' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}>Tag Properties</div>
                    <div className=' py-2 '>
                        <MultiSelect
                            errormessage={inputValues.Properties.error}
                            onChange={handleMultiselectProperties}
                            isMulti={true}
                            options={PropertyNameID}
                            value={inputValues.Properties?.value}
                            lable={'Properties '}
                            size="w-100"
                        />

                    </div>
                    {/* maps & all other sections */}
                    <div className='p-2 rounded-2  mb-2' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}>Project Description</div>

                    <div className='row row-cols-2 pb-3'>
                        <div>
                            <TextArea
                                errorMessage={inputValues.AboutProject.error}
                                placeholder={"Enter About Project"}
                                label={"About Project* "}
                                type={"text"}
                                name="AboutProject"
                                value={inputValues?.AboutProject?.value}
                                onChange={(e) => handleInputChange('AboutProject', e.target.value)}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.AboutProject.error} </p>

                        </div>
                        <div className='pb-5' ref={coworkGoogleMapDivRef}>
                            <label className="fw-700 f-14 text-black form-label">
                                Google Location*
                            </label>
                            <Mapdragger
                                mapValue={inputValues.mapLocation.value}
                                handleChangeMap={handleChangeMap}
                            />
                            {/* <Input
                                type="text"
                                value={inputValues.mapLocation.value}
                                onChange={(e) => handleInputChange('mapLocation', e.target.value)}
                            /> */}
                            {inputValues.mapLocation.error && (
                                <div style={{ color: 'red', fontSize: "12px", marginTop: '-17px' }}>
                                    {inputValues.mapLocation.error}
                                </div>
                            )}
                        </div>
                        {/*  */}
                        <div className=''>
                            <MultiSelect
                                // disabled={disabled}
                                errormessage={inputValues.FeatureAmenities.error}
                                onChange={handleMultiselectFeatureAmenities}
                                isMulti={true}
                                options={AMenity}
                                value={inputValues.FeatureAmenities?.value}
                                lable={'Feature & Amenities*'}
                                size="w-100"
                            />
                        </div>

                        {/*  */}
                        <div>
                            <Input
                                label={"Min Price"}
                                type={"number"}
                                placeholder={"Min Range - Rate/Sqft on Carpet Area"}
                                name="minPrice"
                                prefix={"Rs"}
                                value={inputValues?.minPrice?.value}
                                onChange={(e) => handleInputChange('minPrice', e.target.value)}
                                disabled={false}
                                errorTrue={true}
                            // errorMessage={inputValues.minPrice.error}
                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.minPrice.error} </p>
                        </div>
                        <div>
                            <Input
                                label={"Max Price"}
                                type={"number"}
                                prefix={"Rs"}
                                placeholder={"Max Range - Rate/Sqft on Carpet Area"}
                                name="maxPrice"
                                value={inputValues?.maxPrice?.value}
                                onChange={(e) => handleInputChange('maxPrice', e.target.value)}
                                disabled={false}
                                errorTrue={true}

                            />
                            <p style={{ fontSize: '12px', color: 'red', marginTop: '-15px' }}>{inputValues.maxPrice.error} </p>
                        </div>
                        {/*  */}
                        <div>
                            <Select
                                label="OC Status*"
                                options={OCStatusList}
                                onChange={(e) => handleInputChange('OCStatus', e.target.value)}
                                errorMessage={inputValues.OCStatus.error}
                                isInvalid={!!inputValues.OCStatus.error}
                            />
                        </div>



                        <div className=''>

                            <UploadPropertyDocuments
                                projectID={projectDrafID}
                                gotoStep={handleStepChange}
                                propertyDetail={propertyDetail}
                                step={step}
                                initData={initData}
                                properytyInput={documentproperytyInput}
                                setPropertyInput={setDocumentPropertyInput}
                                uploadDocumentStepButtonRef={uploadDocumentStepButtonRef}
                                setChanged={setUploadFloorChanged}
                                type={type}
                                isExitData={isExitData}

                            />
                            {inputValues.file.error && (
                                <div style={{ color: 'red', fontSize: "12px" }}>
                                    {inputValues.file.error}
                                </div>
                            )}

                        </div>
                        <div>
                            {/* <Select
                                label="Business Space For "
                                options={EndUses}
                                onChange={(e) => handleInputChange('enduse', e.target.value)}
                                // errorMessage={inputValues.enduse.error}
                                isInvalid={!!inputValues.enduse.error}
                            /> */}
                            <MultiSelect
                                // errormessage={inputValues.ChooseUserBase.error}
                                onChange={handleMultiselectEndUse}
                                isMulti={true}
                                options={EndUses}
                                value={inputValues?.enduse?.value}
                                lable={'Business Space For'}
                            // size="w-100"
                            />
                        </div>


                    </div>

                    {/* new section Images & videos */}

                    <div className='p-2 rounded-2  mb-2' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}>Images & Videos</div>
                    <div className='py-3'>
                        <label className="fw-700 f-14 text-black form-label">
                            Interior *
                        </label>
                        <div>
                            < DropzoneProject

                                handleImagesAll={handleImagesAllinterior}
                                name={"interior"}
                                propertyId={projectDrafID}
                                imagesList={interioruploadedImages}
                            />
                            <p style={{ fontSize: '12px', color: 'red' }}>{interiorImagesError} </p>


                        </div>
                    </div>
                    {/*vidoes  */}
                    <div className='py-3'>
                        <label className="fw-700 f-14 text-black form-label">
                            Exterior*
                        </label>
                        <div>
                            < DropzoneProject
                                handleImagesAll={handleImagesAllexterior}
                                name={"exterior"}
                                propertyId={projectDrafID}
                                imagesList={exterioruploadedImages}
                            />
                            <p style={{ fontSize: '12px', color: 'red' }}>{exteriorImagesError} </p>

                        </div>
                    </div>
                    {/*videos  */}
                    <div className='py-3'>
                        <label className="fw-700 f-14 text-black form-label">
                            Videos
                        </label>
                        <div>
                            <VideoDropZone
                                handleImagesAll={handleImagesAllVideos}
                                name={"videos"}
                                projectID={projectDrafID}
                                imagesList={exterioruploadedVideos}
                            />

                        </div>
                    </div>
                    {/* Additional Settings */}
                    <div className='p-2 rounded-2  mb-2' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}>Additional Settings</div>
                    <div className='row row-cols-2 py-3'>
                        <Input
                            label={"Current Tenants "}
                            type={"text"}
                            placeholder={"Enter Current Tenants"}
                            name="CurrentTenants"
                            value={inputValues?.CurrentTenants?.value}
                            onChange={(e) => handleInputChange('CurrentTenants', e.target.value)}
                            disabled={false}
                            errorTrue={true}
                        />

                        <Input
                            label={"No of Parkings Available"}
                            type={"number"}
                            placeholder={"Enter No of Parkings Available"}
                            name="NoofParkingsAvailable"
                            value={inputValues?.NoofParkingsAvailable?.value}
                            onChange={(e) => handleInputChange('NoofParkingsAvailable', e.target.value)}
                            disabled={false}
                            errorTrue={true}

                        />
                    </div>
                    {/*  User Tagging */}
                    <div className='p-2 rounded-2  mb-2' style={{ background: "#004A6E", color: "white", fontWeight: "bold" }}> User Tagging</div>
                    <div className='grid grid-cols-1 gap-2'>

                        <MultiSelect
                            // disabled={disabled}
                            errormessage={inputValues.AssigningPT.error}
                            onChange={handleMultiselectAssigningPT}
                            isMulti={true}
                            options={Pteam}
                            value={inputValues.AssigningPT?.value}
                            lable={'Assign Physical Team POC'}
                        // size="w-100"
                        />



                        <MultiSelect
                            // disabled={disabled}
                            errormessage={inputValues.ChooseUserBase.error}
                            onChange={handleMultiselectChooseUserBase}
                            isMulti={true}
                            options={UserType}
                            value={inputValues.ChooseUserBase?.value}
                            lable={'User Type* '}
                        // size="w-100"
                        />

                    </div>

                    <div className='row '>
                        <div className='d-flex justify-content-end gap-5 mt-5'>
                            {/* <button className='btn btn-primary'>Cancel</button> */}
                            <button type="submit" className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateProject;
